import React, { useEffect, useRef } from 'react';


const SubcategoryScroll = ({ subcategories, selectedSubcategory, onSelectSubcategory, dishesBySubcategory, language }) => {
    const subcategoryContainerRef = useRef(null);



    useEffect(() => {
        if (selectedSubcategory && subcategoryContainerRef.current) {
            const selectedElement = document.getElementById(`button-${selectedSubcategory}`);
            if (selectedElement) {
                const container = subcategoryContainerRef.current;
                const containerRect = container.getBoundingClientRect();
                const elementRect = selectedElement.getBoundingClientRect();

                // Calculate the scroll position to center the selected element
                const scrollLeft = container.scrollLeft + elementRect.left - containerRect.left - containerRect.width / 2 + elementRect.width / 2;

                container.scrollTo({
                    left: scrollLeft,
                    behavior: 'smooth'
                });
            }
        }
    }, [selectedSubcategory]);


    if (!dishesBySubcategory || Object.keys(dishesBySubcategory).length === 0) {
        return <></>;
    }

    return (
        <div className="sticky top-0 z-10 p-1 text-gray-500 bg-white shadow-md">
            <div ref={subcategoryContainerRef} className="flex overflow-x-auto no-scrollbar">
                {subcategories.map((subcategory) => (
                    <button
                        key={subcategory.id}
                        id={`button-${subcategory.enName}`}
                        className={`px-4 py-1 m-1 rounded whitespace-nowrap font-perfetto text-sm ${selectedSubcategory === subcategory.enName ? ' bg-perfetto-olive shadow-md rounded-xl text-white' : ''}`}
                        onClick={() => onSelectSubcategory(subcategory.enName)}
                    >
                        {language === 'en' ? subcategory.enName : subcategory.roName}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SubcategoryScroll;
