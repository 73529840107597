import React from 'react';
import { Spinner } from "flowbite-react";

const DishList = ({ dishesBySubcategory, onSelectDish, subcategoryRefs, language }) => {
    if (!dishesBySubcategory || Object.keys(dishesBySubcategory).length === 0) {
        return (
            <div className="h-[90vh]">

                <div className="flex items-center justify-around w-full h-full">
                    <Spinner aria-label="Center-aligned spinner example" />
                </div>

            </div>
        );
    }

    return (
        <div className="p-4 bg-perfetto-lut">
            {Object.keys(dishesBySubcategory).map((subcategory) => (
                <div
                    key={subcategory}
                    id={subcategory}
                    ref={(el) => (subcategoryRefs.current[subcategory] = el)}
                    className="mb-8"
                >
                    <h2 className="mb-4 text-2xl font-perfetto">{subcategory}</h2>

                    {subcategory === "Colazione" && (
                        <p className="mb-4 text-sm font-perfetto text-gray-600">Luni - Vineri intre 08:30 - 11:00</p>
                    )}
                    {subcategory === "Tabacco" ? (
                        <img
                            src="/img/tabacco.jpg"
                            alt="Tabacco"
                            className="object-cover w-full max-w-[600px] rounded-xl"
                        />
                    ) : (
                        <div className="space-y-4">
                            {dishesBySubcategory[subcategory].map((dish) => (
                                <div
                                    key={dish.id}
                                    className="flex gap-4 p-4 rounded-xl min-h-32 shadow-sm bg-[#fafafa] cursor-pointer"
                                    onClick={() => onSelectDish(dish)}
                                >
                                    {dish.image &&
                                        <img
                                            src={`https://admin.perfettocucinaitaliana.ro/api/public/${dish.image}-300.webp`}
                                            alt={language === 'en' ? dish.enName : dish.roName}
                                            className="object-cover w-24 h-24 rounded-xl"
                                        />
                                    }
                                    <div className="flex flex-col justify-center flex-1 ml-4 ">
                                        <h3 className="text-base font-perfetto">{language === 'en' ? dish.enName : dish.roName}</h3>
                                        <p className="text-xs text-gray-500 line-clamp-2">{language === 'en' ? dish.enDescription : dish.roDescription}</p>
                                    </div>
                                    <div className="flex items-center text-right">
                                        <p className="text-base font-perfetto">{dish.price}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default DishList;
