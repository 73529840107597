import React from 'react';
import TwemojiFlagRomania from './TwemojiFlagRomania'
import TwemojiFlagUnitedKingdom from './TwemojiFlagUnitedKingdom'

const CategorySelector = ({ categories, selectedCategory, onSelectCategory, onToggleLanguage, language }) => {
    return (<div className='flex flex-row bg-white'>
        <div className='w-10 m-2'></div>
        <div className="flex justify-around flex-1 w-10/12 p-1 bg-gray-100 rounded-lg">
            {categories.map((category) => (
                <button
                    key={category.id}
                    className={`px-4 py-1 m-1 rounded whitespace-nowrap font-perfetto text-sm w-1/3 ${selectedCategory === category.id ? ' bg-perfetto-olive shadow-md rounded-xl text-white' : ''}`}
                    onClick={() => onSelectCategory(category.id)}
                >
                    {category.enName}
                </button>

            ))}
        </div>
        <button onClick={onToggleLanguage} className='w-10 m-2'>
            {language === 'en' ? <TwemojiFlagRomania className="w-auto h-6 mx-auto" /> : <TwemojiFlagUnitedKingdom className="w-auto h-6 mx-auto" />}
        </button>
    </div>
    );
};

export default CategorySelector;