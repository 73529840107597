import React, { useEffect, useState } from 'react';
import { Spinner } from "flowbite-react";

const DishDrawer = ({ dish, language, onClose }) => {
    const [isExiting, setIsExiting] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);

    const [showAllergens, setShowAllergens] = useState(false);

    const allergenContentStyle = {
        maxHeight: showAllergens ? 'none' : '0',
        opacity: showAllergens ? 1 : 0,
        transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
        overflow: 'hidden',
    };

    const priceStyle = {
        marginTop: showAllergens ? '2rem' : '0',
        transition: 'margin-top 0.3s ease-in-out',
    };

    const toggleAllergens = () => {
        setShowAllergens(!showAllergens);
    };


    useEffect(() => {
        if (dish) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [dish]);

    if (!dish) {
        return null;
    }

    const imageUrl = `https://admin.perfettocucinaitaliana.ro/api/public/${dish.image}-960.webp`;

    const handleImageLoad = () => {
        setImageLoading(false);
    };

    const handleClose = () => {
        setIsExiting(true);
        setTimeout(() => {
            onClose();
            setIsExiting(false);
        }, 300); // Match the duration of the slideOutDown animation
    };

    return (
        <div className="fixed inset-0 z-50 flex items-end justify-center bg-opacity-50 bg-perfetto-olive/70">
            <div className="absolute inset-0 " onClick={handleClose}></div>
            <div className={`relative bg-white w-full md:w-2/3 lg:w-2/3 p-4 pt-10 rounded-t-lg max-h-[90vh] min-h-[50svh] overflow-y-auto shadow-lg ${isExiting ? 'drawer-exit' : 'drawer-enter'}`}>
                <button
                    className="absolute p-1 text-gray-500 border rounded-full cursor-pointer top-4 right-4 hover:text-gray-700"
                    onClick={handleClose}
                >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h2 className="mb-4 text-xl text-center font-perfetto">{language === 'en' ? dish.enName : dish.roName}</h2>
                {dish.image && (
                    <div className="relative my-8 mt-2">
                        {imageLoading && (
                            <div className="flex items-center justify-center w-full h-full">
                                <Spinner />
                            </div>
                        )}
                        <img
                            src={imageUrl}
                            alt={dish.enName}
                            className={`w-full h-auto rounded-lg ${imageLoading ? 'hidden' : 'block'}`}
                            onLoad={handleImageLoad}
                        />
                    </div>
                )}


                <p className="my-8 text-center text-slate-700">{language === 'en' ? dish.enDescription : dish.roDescription}</p>
                {dish.roNutrition && dish.roNutrition.length > 0 && (
                    <div className='flex flex-col justify-center my-8'>
                        <button
                            className="px-4 py-2 mx-auto text-sm text-center text-white rounded-lg font-perfetto bg-perfetto-olive w-fit"
                            onClick={toggleAllergens}
                        >
                            {language === 'en' ? 'Nutritional values and allergens' : 'Valori nutriționale și alergeni'}
                        </button>

                        <div style={allergenContentStyle}>

                            <p className="my-2 text-sm text-center text-slate-700">
                                {language === 'en' ? dish.enNutrition : dish.roNutrition}
                            </p>
                        </div>

                    </div>
                )}
                <p style={priceStyle} className="my-8 text-lg font-semibold text-center font-perfetto">{dish.price}</p>

            </div>
        </div>
    );
};

export default DishDrawer;
